import React from 'react'
import { Link, useNavigate } from "react-router-dom";

const Card2 = () => {
  const navigate = useNavigate();
  return (
    <div className='fontss'>
         <div
     className='body'
    >
        <div class="card card2" style={{
         
        }}>

<div class="imgBox">
  <img src={require('../../coffeeflavour/irish.png')} alt="mouse corsair"
   class="mouse"/>
</div>

<div class="contentBox">
  <h3>IRISH</h3>
  <h2 class="price">₹525</h2>
  <a
    data-aos="zoom-in-up" 
   onClick={() => navigate("/Irish")} class="buy " 
  style={{backgroundColor:'#f6c38f'}}
  >Buy Now</a>
</div>

</div>
    </div>
    </div>
  )
}

export default Card2