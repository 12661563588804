import React from 'react'
import { Link, useNavigate } from "react-router-dom";
const Card3 = () => {
  const navigate = useNavigate();
  return (
    <div className='fontss'>
                <div class="card card3">

<div class="imgBox">
  <img src={require('../../coffeeflavour/vanilla.png')} alt="mouse corsair"
   class="mouse"/>
</div>

<div class="contentBox">
  <h3>VANILLA</h3>
  <h2 class="price">₹525</h2>
  <a  class="buy"
   onClick={() => navigate("/Vanilla")}
   style={{backgroundColor:'#ddcf8c'}}
   data-aos="zoom-in-up" 
  >Buy Now</a>
</div>

</div>
    </div>
  )
}

export default Card3