import img1 from '../coffeeflavour/vanilla.png'
import img3 from '../coffeeflavour/raspberry.png'
import img2 from '../coffeeflavour/espresso.png'
import img4 from '../coffeeflavour/irish.png'
export const CarouselData = [

    {
    image:img4,
review:
'The unmistakable full-flavour of NESCAFÉ® Classic is what makes our signature coffee so loved all over the world. Savour the wonderfully rich and refreshing aroma of this medium-dark roast.',
name: 'Irish',
status:'Explore Antidote Irish'
},
{
    image: img2,
review:
'It all starts with handpicking the best beans from coffee farms in South India. Awaken your senses with the rich aroma and taste of NESCAFÉ® SUNRISE every morning',
name: 'eSpresso',
status:'Explore Antidote Espresso'
},
{
    image: img3,
review:
'With NESCAFÉ® GOLD, we bring you our finest coffee, artfully crafted by our expert blenders and roasters. An exquisite cup for the moments that matter.',
name: 'Rasberry',
status:'Explore Antidote Rasberry'
},{
    image: img1,
review:
'With NESCAFÉ® GOLD, we bring you our finest coffee, artfully crafted by our expert blenders and roasters. An exquisite cup for the moments that matter.',
name: 'Vanilla',
status:'Explore Antidote Vanilla'
}
];
            