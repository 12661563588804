import React from 'react'

import { Link, useNavigate } from "react-router-dom";
import { DiRequirejs } from 'react-icons/di';
import './Card.scss';
const Card = () => {
    const navigate = useNavigate();
  return (
 
        
    <div
     className='body fontss'
    >
        <div 
        // data-aos="zoom-in-up"
        class="card">

<div class="imgBox">
  <img src={require('../../coffeeflavour/espresso.png')} alt="mouse corsair"
   class="mouse"/>
</div>

<div class="contentBox">
  <h3>Espresso</h3>
  <h2 class="price"> ₹525<small></small> </h2>
  <a
    data-aos="zoom-in-up" 
  onClick={() => navigate("/Espresso")} class="buy">Buy Now</a>
</div>

</div>

    </div>
  

  )
}

export default Card