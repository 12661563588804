import React from "react";
import Background1 from "../upload/logo_white.png";
import "./frontend.min.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {HiShoppingCart } from "react-icons/hi";
import {GrClose } from "react-icons/gr";
import './Button.scss'

import { Link, useNavigate } from "react-router-dom";
import logo from "../img/Antidote Logo0.svg";
import "./Navbar.css";
import Nav from "./Nav";
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <div id="loftloader-wrapper" class="pl-imgloading" data-show-close-time="15000" data-max-load-time="0">
        <div class="loader-inner">
            <div id="loader">
                <div class="imgloading-container"><span style={{ backgroundImage: `url(${bac1})` }} ></span></div>
                <img data-no-lazy="1" class="skip-lazy" alt="loader image" src={require('../upload/logo_white.png')} />
            </div>
        </div>
        <div class="loader-section section-fade"></div>
        <div class="loader-close-button" style={{display: 'none'}}><span class="screen-reader-text">Close</span></div>
    </div> */}
      <section>
        <div id="wrapper" className="hasbg transparent  ">
          <div id="elementor-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="3099"
              className="elementor custom-css-style"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-a216edb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    // style={{ padding: "18px" }}
                    data-id="a216edb"
                    data-element_type="section"
                    data-settings='{"craftcoffee_ext_is_background_parallax":"false"}'
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-row">
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-eb2db78 elementor-hidden-tablet elementor-hidden-phone"
                          data-id="eb2db78"
                          data-element_type="column"
                          data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-d6b5ce3 elementor-widget elementor-widget-craftcoffee-navigation-menu"
                                data-id="d6b5ce3"
                                data-element_type="widget"
                                data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                                data-widget_type="craftcoffee-navigation-menu.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="themegoods-navigation-wrapper menu_style1">
                                    <div className="menu-main-menu-container">
                                      <ul
                                        id="nav_menu34"
                                        className="nav"
                                        style={{
                                          listStyle: "none",
                                          display: "flex",
                                          alignContent: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <li className="menu-item current-menu-ancestor current-menu-parent menu-item-has-children arrow lii">
                                          <a className="aa">
                                            <Link to={"/"}>Home</Link>
                                          </a>
                                          <ul className="sub-menu"></ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children arrow lii">
                                          <a className="aa">
                                            <Link to={"/products"}>
                                              Products
                                            </Link>
                                          </a>
                                        </li>
                                        <li className="menu-item menu-item-has-children arrow lii">
                                          <a className="aa">
                                            <Link to={"/about"}>About Us</Link>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-8dfe958"
                          data-id="8dfe958"
                          data-element_type="column"
                          data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-6f23744 elementor-absolute elementor-widget elementor-widget-image"
                                data-id="6f23744"
                                data-element_type="widget"
                                data-settings='{"_position":"absolute","craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-image">
                                  
                                      <img
                                        width="180"
                                        height="180"
                                        src={logo}
                                        className="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-b401b7a elementor-hidden-phone"
                          data-id="b401b7a"
                          data-element_type="column"
                          data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-9966067 elementor-widget__width-auto elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
                                data-id="9966067"
                                data-element_type="widget"
                                data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                                data-widget_type="social-icons.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-social-icons-wrapper elementor-grid">
                                    <div className="elementor-grid-item">
                                      <a
                                        href="#"
                                        className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-fac257c"
                                        target="_blank"
                                      >
                                        <span className="elementor-screen-only">
                                          Facebook
                                        </span>
                                        <i className="fab fa-facebook"></i>
                                      </a>
                                    </div>
                                    <div className="elementor-grid-item">
                                      <a
                                        href="#"
                                        className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-4238c6b"
                                        target="_blank"
                                      >
                                        <span className="elementor-screen-only">
                                          Instagram
                                        </span>
                                        <i className="fab fa-instagram"></i>
                                      </a>
                                    </div>
                                    <div className="elementor-grid-item">
                                      <a
                                        href="#"
                                        className="elementor-icon elementor-social-icon elementor-social-icon-yelp elementor-repeater-item-e0f5af3"
                                        target="_blank"
                                      >
                                        <span className="elementor-screen-only">
                                          Yelp
                                        </span>
                                        <i className="fab fa-yelp"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-9c9f0b7 elementor-widget__width-auto elementor-widget elementor-widget-craftcoffee-search"
                                data-id="9c9f0b7"
                                data-element_type="widget"
                                data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                                data-widget_type="craftcoffee-search.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="craftcoffee-search-icon">
                                    <a
                                      data-open="tg_search_9c9f0b7"
                                      href="javascript:;"
                                    >
                                      <i
                                        aria-hidden="true"
                                        className="fas fa-search"
                                      ></i>
                                    </a>
                                  </div>

                                  <div
                                    id="tg_search_9c9f0b7"
                                    className="craftcoffee-search-wrapper"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-fcec661 fttt"
                          data-id="fcec661"
                          data-element_type="column"
                          data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-ff52274 elementor-align-center elementor-widget-tablet__width-auto elementor-hidden-phone elementor-widget elementor-widget-button"
                                data-id="ff52274"
                                data-element_type="widget"
                                data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                                data-widget_type="button.default"
                              >
                                {/* <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                <a
                                href="tel:234.567.3455"
                                className="elementor-button-link elementor-button elementor-size-sm"
                                    role="button"
                                    >
                                    <span className="elementor-button-content-wrapper">
                                      <span className="elementor-button-text">
                                      234.567.3455
                                      </span>
                                      </span>
                                  </a>
                                </div>
                              </div> */}
                                <div
                                  className="nonee"
                                  onClick={() => navigate("/cart")}
                                >
                                       <div id="containert">
  <a class="learn-more a11">
    <span class="circle" aria-hidden="true">
      <span class="">
      <HiShoppingCart
        size={34}
        
         style={{color:'white',marginLeft: "6px",
         marginTop:" 9px"}}
         />
      </span>
    </span>
    <span style={{
    fontWeight: "100"

    }} class="button-text">Cart(0)</span>
  </a>
</div>

                                </div>
                              </div> 
                             
                              <div
                                className="elementor-element elementor-element-0b79fea elementor_mobile_nav elementor-widget__width-auto elementor-hidden-desktop elementor-view-default elementor-widget elementor-widget-icon"
                                data-id="0b79fea"
                                data-element_type="widget"
                                data-settings='{"craftcoffee_ext_is_scrollme":"false","craftcoffee_ext_is_smoove":"false","craftcoffee_ext_is_parallax_mouse":"false","craftcoffee_ext_is_infinite":"false","craftcoffee_ext_is_fadeout_animation":"false"}'
                                data-widget_type="icon.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-wrapper full1"
                                  style={{display:'flex',alignItems:'center'}}
                                  >
                                    {/* <details>
  <summary></summary>
  <nav class="menu">
    <a href="#link">Home</a>
    <a href="#link">Work</a>
    <a href="#link">Links</a>
    <a href="#link">Contact</a>
    <a href="#link">About</a>
    
  </nav>
</details> */}

                               
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-3727a07 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="3727a07"
                    data-element_type="section"
                    data-settings='{"stretch_section":"section-stretched","craftcoffee_ext_is_background_parallax":"false"}'
                  ></section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <div className="mobile-menu1">

<div style={{display:'flex'}} className="">
        <div class="nav1">
          <input type="checkbox" />
          <span className="xx">
          </span>
          <span className="xx">
          </span>
          <span className="xx"></span>
          <div class="menu1">
            <li>
              <a>
                <Link to={"/"}>Home</Link>
              </a>
            </li>
            <li>
              <a>
                <Link to={"/products"}>
                  Products
                </Link>
              </a>
            </li>
            <li>
              <a 
              style={{    whiteSpace: "pre"}}>
                <Link to={"/about"}>
                  About Us
                </Link>
              </a>
            </li>
          </div>
        </div>
      </div>
      <section  >
      <div 
      style={{marginRight:'8px',marginTop:'9px'}}
      onClick={() => navigate("/cart")}>
    
      
     
        <HiShoppingCart
        // size={30}
        
         style={{color:'white'}}
         />
      
    
  </div>
      </section>

      </div>
 
    </div>
  );
};

export default Navbar;
